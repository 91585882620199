<template>
  <div id="workflows-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      申請表
    </h2>

    <div v-if="checkPermission(permissions, 'system.workflow.email-workflow') || checkPermission(permissions, 'system.workflow.account-workflow')">
      <ul class="nav nav-tabs mt-5" role="tablist">
        <li v-for="(item, key) in workflowTypes" :key="key" :id="`workflow-${key}-tab`" class="nav-item flex-1"
            role="presentation">
          <template v-if="checkPermission(permissions, `system.workflow.${item.type}-workflow`)">
            <button class="nav-link w-full py-2" :class="{'active': currentType === item.type}" type="button" @click="changeType(item)"> {{ item.name }}
            </button>
          </template>
        </li>
      </ul>

      <div class="tab-content border-l border-r border-b mb-5">
        <div class="tab-pane leading-relaxed p-5 active">

          <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap justify-between align-items-bottom mt-2">
            <div class="flex flex-column justify-end mb-1 sm:mb-0">
              <router-link :to="`/workflows/create-${currentType}-workflow`" class="btn btn-primary shadow-md mr-2 whitespace-nowrap">遞交申請</router-link>
            </div>

            <div class="w-full sm:w-auto">
              <div class="flex justify-center align-items-bottom">
                <div class="w-full flex-nowrap sm:w-auto sm:mt-0 sm:ml-auto md:ml-0 flex-col sm:flex-row flex">
                  <div class="flex flex-nowrap justify-start">
                    <div class="flex flex-column justify-end mr-2 mb-1 sm:mb-0">
                      <button class="btn whitespace-nowrap" :class="{'btn-primary': isMine, 'btn-secondary': !isMine}" @click="isMine = true">我的申請</button>
                    </div>

                    <div v-if="(this.currentType == 'email' && checkPermission(permissions, 'system.workflow.email-workflow.view-all')) || (this.currentType == 'account' && checkPermission(permissions, 'system.workflow.account-workflow.view-all'))" class="flex flex-column justify-end mr-2 mb-1 sm:mb-0">
                      <button class="btn whitespace-nowrap" :class="{'btn-primary': !isMine, 'btn-secondary': isMine}" @click="isMine = false">所有申請</button>
                    </div>
                  </div>

                  <div class="flex flex-wrap sm:flex-nowrap justify-end">
                    <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
                      <label class="form-label text-xs">申請狀態</label>
                      <select id="application-status" v-model="currentApplicationStatus" class="form-select" aria-label="請選擇申請狀態" @change="updateUrl">
                        <option v-for="(item, key) in allApplicationStatus" :key="key" :value="item.value">{{ item.name }}</option>
                      </select>
                    </div>

                    <div v-if="currentType === 'account'" class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
                      <label class="form-label text-xs">申請類別</label>
                      <select id="application-types" v-model="currentApplicationType" class="form-select mr-2" aria-label="請選擇申請類別" @change="updateUrl">
                        <option value="">-- 請選擇 --</option>
                        <option v-for="(item, key) in applicationTypes" :key="key" :value="item.value">{{ item.key }}</option>
                      </select>
                    </div>

                    <div v-if="currentType === 'account'" class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
                      <label class="form-label text-xs">生效</label>
                      <select id="site" v-model="isEffective" class="form-select mr-2" aria-label="請選擇" @change="updateUrl">
                        <option :value="true">是</option>
                        <option :value="false">否</option>
                      </select>
                    </div>

                    <div v-if="currentType === 'account'"  class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32" >
                      <label class="form-label text-xs">主任級或以上</label>
                      <select id="site" v-model="isHighLevel" class="form-select mr-2" aria-label="請選擇" @change="updateUrl">
                        <option :value="false">全部</option>
                        <option :value="true">主任級或以上</option>
                      </select>
                    </div>

                    <div v-if="currentType === 'account'"  class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
                      <label class="form-label text-xs">服務單位</label>
                      <select id="application-types" v-model="currentCentreId" class="form-select mr-2" aria-label="請選擇" @change="updateUrl">
                        <option value="">全部</option>
                        <option v-for="(item, key) in centers" :key="key" :value="item.centre_id">{{ item.abbrev }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="overflow-x-auto mt-5">
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="whitespace-nowrap"><order-button title="ID" column="application_id" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><order-button title="服務單位" column="site_id" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><order-button title="職員編號" column="staff_number" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th v-if="currentType === 'email'" class="whitespace-nowrap"><order-button title="申請要求" column="request_for" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th v-if="currentType === 'account'" class="whitespace-nowrap"><order-button title="申請類別" column="application_type" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th v-if="currentType === 'account'" class="whitespace-nowrap"><order-button title="顯示名稱" column="display_name" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><order-button title="申請狀態" column="application_status" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="text-center whitespace-nowrap"><order-button title="建立時間" column="created_at" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="text-center whitespace-nowrap">{{ currentType === 'email' ? '檢視' : '檢視/更改'}}</th>
                <th class="text-center whitespace-nowrap">動作</th>
              </tr>
              </thead>
              <tbody>
              <tr class="" v-for="(item, key) in workflows" :key="key">
                <td>{{ item.application_id }}</td>
                <td><span v-if="item.centre_id != null">{{ getObjectValue(centers, 'centre_id', item.centre_id, 'abbrev') }} ({{ getObjectValue(centers, 'centre_id', item.centre_id, 'name_en') }})</span><span v-else>-</span></td>
                <td class="">{{ (item.staff_number) }}</td>
                <td class="w-56" v-if="currentType === 'email'">{{ getObjectValue(requestTypes, 'value', item.request_for, 'key') }}</td>
                <td class="w-56" v-if="currentType === 'account'">{{ getObjectValue(applicationTypes, 'value', item.application_type, 'key') }}</td>
                <td class="w-56" v-if="currentType === 'account'">{{ item.display_name }}</td>
                <td>{{ getObjectValue(allApplicationStatus, 'value', item.application_status, 'name') }}</td>
                <td>{{ item.created_at }}</td>
                <td class="table-report__action text-center w-56">
                  <router-link v-if="currentType === 'account' && item.application_status === 'DRAFT' || item.application_status === 'REQUEST_MODIFICATION' || ((item.application_status === 'PENDING' || item.application_status === 'PENDING_BOSS') && (isApprover || isHighLevelApprover))" :to="`/workflows/update-${currentType}-workflow/${item.application_id}`" class="btn btn-success">
                    <font-awesome-icon icon="fa-regular fa-pen-to-square" class="text-white" />
                  </router-link>

                  <router-link v-else :to="`/workflows/${currentType}-workflows/${item.application_id}`" class="btn btn-success">
                    <font-awesome-icon icon="fa-regular fa-eye" class="text-white" />
                  </router-link>

                  <button type="button" class="btn btn-primary ml-1" @click="onDownload(item)" v-if="!downloading && currentType === 'email'">
                    <font-awesome-icon icon="fa-regular fa-file" class="text-white" />
                  </button>
                  <loading-ring v-if="downloading && currentDownload === item.application_id"></loading-ring>
                </td>
                <td class="table-report__action">
                  <div class="flex justify-center items-center">
                    <router-link :to="`/workflows/update-${currentType}-workflow/${item.application_id}?copy=true`" class="flex items-center mr-3 text-pending">
                      <font-awesome-icon icon="fa-solid fa-copy" />
                      &nbsp;複製 </router-link>
                    <router-link :to="`/workflows/update-${currentType}-workflow/${item.application_id}`" class="flex items-center mr-3" v-if="currentType === 'email' && (item.application_status === 'DRAFT' || item.application_status === 'REQUEST_MODIFICATION' || ((item.application_status === 'PENDING' || item.application_status === 'PENDING_BOSS') && (isApprover || isHighLevelApprover)))">
                      <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                      &nbsp;更改 </router-link>
                    <button class="flex items-center text-danger" @click="openDeleteModal(item.application_id)">
                      <font-awesome-icon icon="fa-regular fa-trash-can" />
                      &nbsp;刪除 </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="hidden md:block mx-auto text-slate-500 text-center mt-5">正在顯示第 {{ workflows.length !== 0 ? from : 0 }} 至 {{ to ?? 0 }} 份申請表</div>
          </div>
        </div>
      </div>

      <!-- BEGIN: Pagination -->
      <pagination-row :pages="pages" :current-page.sync="currentPage" :item-per-page.sync="itemPerPage"></pagination-row>
      <!-- END: Pagination -->

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要刪除此申請？
                  <br>
                  這動作不能還原。
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions', 'currentSite'],
  data() {
    return {
      mounted: false,
      workflowTypes: [
        {
          type: 'email',
          name: '東華三院電郵系統帳戶設定申請表'
        },
        {
          type: 'account',
          name: 'iManage 帳戶申請/更新/刪除'
        }
      ],
      currentType: null,
      currentApplicationStatus: '',
      currentApplicationType: '',
      isEffective: true,
      isHighLevel: false,
      currentCentreId: '',
      orderBy: 'created_at',
      order: 'DESC',
      downloading: false,
      currentDownload: null,

      centers: [],

      workflows: [],

      itemPerPage: 10,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      isMine: true,

      isApprover: false,
      isHighLevelApprover: false,

      requestTypes: [
        {
          key: '新增使用者帳戶',
          value: 1
        },
        {
          key: '新增功能職務帳戶',
          value: 2
        },
        {
          key: '更改帳戶資料',
          value: 3
        },
        {
          key: '刪除帳戶',
          value: 4
        },
        {
          key: '其他',
          value: 5
        }
      ],
      accountCategories: [
        {
          key: '行政總監/科主任',
          value: 1
        },
        {
          key: '服務單位主任',
          value: 2
        },
        {
          key: '學校校長',
          value: 3
        },
        {
          key: '部門主管',
          value: 4
        },
        {
          key: '行政人員',
          value: 5
        },
        {
          key: '總部行政秘書',
          value: 6
        },
        {
          key: '一般使用者帳戶',
          value: 7
        },
        {
          key: '其他 (功能職務帳戶)',
          value: 8
        }
      ],
      roles: [],
      allApplicationStatus: [
        { name: '全部', value: 'ALL' },
        { name: '等待最終審批', value: 'PENDING_BOSS' },
        { name: '要求修改', value: 'REQUEST_MODIFICATION' },
        { name: '草稿', value: 'DRAFT' },
        { name: '已拒絕', value: 'REJECTED' },
        { name: '等待審批', value: 'PENDING' },
        { name: '已完成', value: 'SUCCESS' }
      ]
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite(val) {
      if (val) {
        this.init()
      }
    },
    currentType(val) {
      this.loadWorkflows()
    },
    currentPage(val) {
      this.loadWorkflows(false)
    },
    currentApplicationStatus(val) {
      this.loadWorkflows()
    },
    currentApplicationType(val) {
      this.loadWorkflows()
    },
    isEffective() {
      this.loadWorkflows()
    },
    isHighLevel() {
      this.loadWorkflows()
    },
    currentCentreId() {
      this.loadWorkflows()
    },
    orderBy(val) {
      this.loadWorkflows()
    },
    order(val) {
      this.loadWorkflows()
    },
    itemPerPage(val) {
      this.loadWorkflows()
    },
    search(val) {
      this.loadWorkflows()
    },
    isMine(val) {
      this.loadWorkflows()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite != null) {
        this.currentType = this.$route.query.type ?? this.workflowTypes[0].type
        this.currentApplicationStatus = this.$route.query.applicationStatus ?? 'ALL'
        this.currentApplicationType = this.$route.query.applicationType ?? ''
        this.isEffective = this.$route.query.isEffective ?? true
        this.isHighLevel = this.$route.query.isHighLevel ?? false
        this.currentCentreId = this.$route.query.centreId ?? ''
        await this.loadConfigs()
        await this.loadCenters()
        await this.loadRoles()
        this.mounted = true
      }
    },
    changeType(item) {
      if (this.currentType !== item.type) {
        this.currentType = item.type
        if (this.currentType === 'email') {
          if (!this.checkPermission(this.permissions, 'system.workflow.email-workflow.view-all')) {
            this.isMine = true
          }
          this.$router.replace({ path: '/workflows', query: { type: item.type, applicationStatus: this.currentApplicationStatus } })
        } else {
          if (!this.checkPermission(this.permissions, 'system.workflow.account-workflow.view-all')) {
            this.isMine = true
          }
          this.$router.replace({ path: '/workflows', query: { type: item.type, applicationStatus: this.currentApplicationStatus, applicationType: this.currentApplicationType, isEffective: this.isEffective, isHighLevel: this.isHighLevel, centreId: this.currentCentreId } })
        }
      }
    },
    updateUrl() {
      this.$router.replace({ path: '/workflows', query: { type: this.currentType, applicationStatus: this.currentApplicationStatus, applicationType: this.currentApplicationType, isEffective: this.isEffective, isHighLevel: this.isHighLevel, centreId: this.currentCentreId } })
    },
    async loadConfigs() {
      this.configs = await this.getSystemConfig('workflow')

      this.isApprover = parseInt(this.configs.approver_site_id) === parseInt(this.currentSite.site_id)
      this.isHighLevelApprover = parseInt(this.configs.high_level_approver_site_id) === parseInt(this.currentSite.site_id)
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadRoles() {
      this.roles = await this.getRoles()
    },
    async loadWorkflows(resetCurrentPage = true) {
      const response = await this.getWorkflows(this.currentType, this.itemPerPage, this.currentPage, this.currentApplicationStatus, this.currentApplicationType, this.isEffective, this.isHighLevel, this.currentCentreId, this.orderBy, this.order, this.isMine)

      if (response.code === 0) {
        this.workflows = response.data
        this.pages = response.meta.page.last_page
        this.from = response.meta.page.from
        this.to = response.meta.page.to
      }

      if (resetCurrentPage) {
        this.currentPage = 1
      }
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDownload(item) {
      this.downloading = true
      this.currentDownload = item.application_id

      const response = await this.downloadWorkflow(this.currentType, item.application_id)
      const blob = new Blob([response], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `f1-${item.application_id}.pdf`
      link.click()
      URL.revokeObjectURL(link.href)
      this.downloading = false
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteWorkflow(this.currentType, this.deleteId)

      let option = {
        title: '成功',
        message: '申請已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        this.loadWorkflows()
      } else {
        option = {
          title: '未能刪除申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#workflows-page {
  .tab-content {
    background-color: #FFF;

    table {
      thead {
        th:first-child, th:nth-child(3) {
          width: 120px;
        }
      }

      .table-report__action {
        width: 200px;
      }
    }
  }
}
</style>
