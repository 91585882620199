<template>
  <div id="contact-form">
    <!-- BEGIN: Form Layout -->
    <div class="box p-5">

      <div class="mt-3">
        <div class="flex">
          <div class="flex-1 mr-1">
            <label for="staff_number" class="form-label"><span class="text-danger">*</span> 職員編號</label>
            <input v-if="mode === 'create'" v-model="staffNumber" id="staff_number" type="text" class="form-control w-full"
                   placeholder="請輸入職員編號" maxlength="7" @keypress="isLetterOrNumber($event)" @change="resetStaffNumber">
            <div v-if="'staff_number' in errors" class="text-danger mt-2" v-html="errors.staff_number"></div>
            <div v-if="mode === 'update'">{{ staffNumber }}</div>
          </div>

          <div v-if="mode === 'create'" class="flex align-items-end">
            <button class="btn btn-primary w-24" @click="onCheckUserExist" :disabled="staffNumber == null || staffNumber === ''">搜尋</button>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <div class="flex-1">
          <label for="application_type" class="form-label"><span class="text-danger">*</span> 申請類別</label>
          <select id="application_type" v-model="form.application_type" class="form-select"
                  aria-label="請選擇申請類別" :disabled="staffNumber == null || staffNumber === '' || form.staff_number == null || form.staff_number === '' || mode === 'update' || isDeletedUser">
            <option value="">--- 請選擇 ---</option>
            <template v-for="(item, key) in applicationTypes">
              <option v-if="item.existUser === isExistUser || mode === 'update'" :key="key" :value="item.value">{{ item.key }}</option>
            </template>

          </select>
          <div v-if="'application_type' in errors" class="text-danger mt-2"
               v-html="errors.application_type"></div>
        </div>
      </div>

      <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
        <span class="font-medium">基本資料</span>
      </div>

      <div>
        <div class="flex justify-between align-items-center mb-1">
          <label class="form-label">大頭圖像</label>
          <button type="button" class="btn btn-danger" v-if="form.avatar_base64 != null" @click="onRemoveImage">
            移除
          </button>
        </div>

        <form data-single="true" action="/" class="dropzone">
          <div class="fallback"><input name="file" type="file"/></div>
          <div class="dz-message" data-dz-message>
            <div class="text-lg font-medium">將圖片拖放到此處或單擊以上傳</div>
            <div class="text-slate-500"> 支援檔案：JPG, JPEG, PNG, BMP, GIF</div>
            <div class="text-slate-500"> 圖片尺吋：735x725 (300ppi)</div>
            <div class="text-slate-500"> 檔案大小：不小於1MB</div>
          </div>
        </form>
        <div v-if="'avatar_base64' in errors" class="text-danger mt-2" v-html="errors.avatar_base64"></div>
      </div>

      <div class="mt-3" v-if="mode === 'update' && data.avatar">
        <label for="avatar" class="form-label">現時圖像</label>
        <a :href="serverUrl + '/public-resources' + data.avatar" target="_blank"
           class="block w-48 h-48 flex-none image-fit rounded-md overflow-hidden">
          <img alt="大頭圖像" :src="serverUrl + '/public-resources' + data.avatar">
        </a>
      </div>

      <div class="mt-5">
        <div class="sm:grid grid-cols-2 gap-2">
          <div>
            <label for="centre_id" class="form-label"><span class="text-danger">*</span> 所屬部/組/服務單位</label>
            <select id="centre_id" v-model="form.centre_id" class="form-select" aria-label="請選擇所屬部/組/服務單位">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in centers" :key="key" :value="item.centre_id">{{ item.abbrev }}
                {{ item.name_zh }}
              </option>
            </select>
            <div v-if="'centre_id' in errors" class="text-danger mt-2" v-html="errors.centre_id"></div>
          </div>

          <div>
            <label for="position_id" class="form-label"><span class="text-danger">*</span> 職位</label>
            <select id="position_id" v-model="form.position_id" class="form-select" aria-label="請選擇職位">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in positions" :key="key" :value="item.position_id">{{ item.name }}</option>
            </select>
            <div v-if="'position_id' in errors" class="text-danger mt-2" v-html="errors.position_id"></div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <div class="flex">
          <div class="flex-1 mr-1">
            <label>主任級或以上</label>
            <div class="form-switch mt-2">
              <input v-model="form.is_high_level" type="checkbox" class="form-check-input">
            </div>
            <div v-if="'is_high_level' in errors" class="text-danger mt-2" v-html="errors.is_high_level"></div>
          </div>
          <div class="flex-1 ml-1">
            <label>註冊社工</label>
            <div class="form-switch mt-2">
              <input v-model="form.is_register_social_worker" type="checkbox" class="form-check-input">
            </div>
            <div v-if="'is_register_social_worker' in errors" class="text-danger mt-2"
                 v-html="errors.is_register_social_worker"></div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <label for="honorific_title" class="form-label"><span class="text-danger">*</span> 稱謂</label>
        <select id="honorific_title" v-model="form.honorific_title" class="form-select" aria-label="請選擇稱呼">
          <option value="">--- 請選擇 ---</option>
          <option v-for="(item, key) in titles" :key="key" :value="item.value">{{ item.key }}</option>
        </select>
        <div v-if="'honorific_title' in errors" class="text-danger mt-2" v-html="errors.honorific_title"></div>
      </div>

      <div class="mt-3">
        <div class="flex">
          <div class="flex-1 mr-1">
            <label for="last_name_zh" class="form-label"><span class="text-danger">*</span> 中文姓氏</label>
            <input v-model="form.last_name_zh" id="last_name_zh" type="text" class="form-control w-full"
                   placeholder="請輸入姓氏">
            <div v-if="'last_name_zh' in errors" class="text-danger mt-2" v-html="errors.last_name_zh"></div>
          </div>
          <div class="flex-1">
            <label for="first_name_zh" class="form-label"><span class="text-danger">*</span> 中文名字</label>
            <input v-model="form.first_name_zh" id="first_name_zh" type="text" class="form-control w-full"
                   placeholder="請輸入名字">
            <div v-if="'first_name_zh' in errors" class="text-danger mt-2" v-html="errors.first_name_zh"></div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <div class="flex">
          <div class="flex-1 mr-1">
            <label for="last_name" class="form-label"><span class="text-danger">*</span> 英文姓氏</label>
            <input v-model="form.last_name_en" id="last_name_en" type="text" class="form-control w-full"
                   placeholder="請輸入姓氏">
            <div v-if="'last_name_en' in errors" class="text-danger mt-2" v-html="errors.last_name_en"></div>
          </div>
          <div class="flex-1 mr-1">
            <label for="first_name_en" class="form-label"><span class="text-danger">*</span> 英文名字</label>
            <input v-model="form.first_name_en" id="first_name_en" type="text" class="form-control w-full"
                   placeholder="請輸入名字">
            <div v-if="'first_name_en' in errors" class="text-danger mt-2" v-html="errors.first_name_en"></div>
          </div>
          <div class="flex-1">
            <label for="other_name_en" class="form-label">英文別名</label>
            <input v-model="form.other_name_en" id="other_name_en" type="text" class="form-control w-full"
                   placeholder="請輸入別名">
            <div v-if="'other_name_en' in errors" class="text-danger mt-2" v-html="errors.other_name_en"></div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <label for="display_name" class="form-label">顯示名稱</label>
        <input v-model="form.display_name" id="display_name" type="text" class="form-control w-full"
               placeholder="請輸入顯示名稱" readonly>
        <div v-if="'display_name' in errors" class="text-danger mt-2" v-html="errors.display_name"></div>
      </div>

      <div class="mt-3">
        <label for="email" class="form-label"><span class="text-danger">*</span> 電郵地址</label>
        <input v-model="form.email" id="email" type="email" class="form-control w-full"
               placeholder="請輸入電郵地址">
        <div v-if="'email' in errors" class="text-danger mt-2" v-html="errors.email"></div>
      </div>

      <div class="mt-3">
        <div class="flex">
          <div class="flex-1 mr-1">
            <label for="office_number" class="form-label"><span class="text-danger">*</span> 辦公室電話</label>
            <input v-model="form.office_number" id="office_number" type="text" class="form-control w-full"
                   placeholder="請輸入辦公室電話" @keypress="isSlashOrNumber($event)"
                   @keyup="formatPhone('office_number', $event)">
            <div v-if="'office_number' in errors" class="text-danger mt-2" v-html="errors.office_number"></div>
          </div>

          <div class="flex-1">
            <label for="fax" class="form-label"><span class="text-danger">*</span> 傳真</label>
            <input v-model="form.fax" id="fax" type="text" class="form-control w-full" placeholder="請輸入傳真"
                   @keypress="isSlashOrNumber($event)" @keyup="formatPhone('fax', $event)">
            <div v-if="'fax' in errors" class="text-danger mt-2" v-html="errors.fax"></div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <div class="flex">
          <div class="flex-1 mr-1">
            <label for="contact-number" class="form-label">手提電話</label>
            <input v-model="form.contact_number" id="contact_number" type="text" class="form-control w-full"
                   placeholder="請輸入手提電話" @keypress="isSlashOrNumber($event)"
                   @keyup="formatPhone('contact_number', $event)">
            <div v-if="'contact_number' in errors" class="text-danger mt-2" v-html="errors.contact_number"></div>
          </div>

          <div class="flex-1">
            <label for="other_number" class="form-label">其他電話</label>
            <input v-model="form.other_number" id="other_number" type="text" class="form-control w-full"
                   placeholder="請輸入其他電話" @keypress="isSlashOrNumber($event)"
                   @keyup="formatPhone('other_number', $event)">
            <div v-if="'other_number' in errors" class="text-danger mt-2" v-html="errors.other_number"></div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <label for="birthdate" class="form-label">出生日期</label>
        <div id="birthdate">
          <date-picker v-model="birthdate" type="date" placeholder="請選擇出生日期"
                       input-class="form-control w-full" :disabled-date="notAfterDate"></date-picker>
        </div>
        <div v-if="'birthdate' in errors" class="text-danger mt-2" v-html="errors.birthdate"></div>
      </div>

      <div class="mt-3">
        <label for="special_skill" class="form-label">專長</label>
        <input v-model="form.special_skill" id="special_skill" type="text" class="form-control w-full"
               placeholder="請輸入專長">
        <div v-if="'special_skill' in errors" class="text-danger mt-2" v-html="errors.special_skill"></div>
      </div>

      <div class="mt-3">
        <label for="about_me" class="form-label">關於我</label>
        <input v-model="form.about_me" id="about_me" type="text" class="form-control w-full"
               placeholder="請輸入關於我">
        <div v-if="'about_me' in errors" class="text-danger mt-2" v-html="errors.about_me"></div>
      </div>

      <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
        <span class="font-medium">其他資料</span>
      </div>

      <div class="mt-3">
        <label for="personal_website" class="form-label">個人網站</label>
        <input v-model="form.personal_website" id="personal_website" type="text" class="form-control w-full"
               placeholder="請輸入個人網站">
        <div v-if="'personal_website' in errors" class="text-danger mt-2" v-html="errors.personal_website"></div>
      </div>

      <div class="mt-3">
        <label for="blog_website" class="form-label">博客網站</label>
        <input v-model="form.blog_website" id="blog_website" type="text" class="form-control w-full"
               placeholder="請輸入博客網站">
        <div v-if="'blog_website' in errors" class="text-danger mt-2" v-html="errors.blog_website"></div>
      </div>

      <div class="mt-3">
        <label for="other_website" class="form-label">其他網站</label>
        <input v-model="form.other_website" id="blog_website" type="text" class="form-control w-full"
               placeholder="請輸入其他網站">
        <div v-if="'other_website' in errors" class="text-danger mt-2" v-html="errors.other_website"></div>
      </div>

      <div class="mt-3">
        <label for="group" class="form-label">團體及組織</label>
        <input v-model="form.group" id="group" type="text" class="form-control w-full"
               placeholder="請輸入團體及組織">
        <div v-if="'group' in errors" class="text-danger mt-2" v-html="errors.group"></div>
      </div>

      <div class="mt-3">
        <label for="honors" class="form-label">榮譽及獎項</label>
        <input v-model="form.honors" id="honors" type="text" class="form-control w-full"
               placeholder="請輸入榮譽及獎項">
        <div v-if="'honors' in errors" class="text-danger mt-2" v-html="errors.honors"></div>
      </div>

      <div class="mt-3">
        <label for="interest" class="form-label">興趣</label>
        <input v-model="form.interest" id="interest" type="text" class="form-control w-full"
               placeholder="請輸入興趣">
        <div v-if="'interest' in errors" class="text-danger mt-2" v-html="errors.interest"></div>
      </div>

      <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
        <span class="font-medium">教育</span>
      </div>

      <div class="mt-3">
        <label for="school" class="form-label">就讀學校</label>
        <input v-model="form.school" id="school" type="text" class="form-control w-full"
               placeholder="請輸入就讀學校">
        <div v-if="'school' in errors" class="text-danger mt-2" v-html="errors.school"></div>
      </div>

      <div class="mt-3">
        <div class="flex">
          <div class="flex-1 mr-1">
            <label for="academic" class="form-label">獲取的學歷</label>
            <input v-model="form.academic" id="academic" type="text" class="form-control w-full"
                   placeholder="請輸入獲取的學歷">
            <div v-if="'academic' in errors" class="text-danger mt-2" v-html="errors.academic"></div>
          </div>

          <div class="flex-1 mr-1">
            <label for="academic_department" class="form-label">學系</label>
            <input v-model="form.academic_department" id="academic_department" type="text"
                   class="form-control w-full" placeholder="請輸入學系">
            <div v-if="'academic_department' in errors" class="text-danger mt-2"
                 v-html="errors.academic_department"></div>
          </div>

          <div class="flex-1">
            <label for="academic_other" class="form-label">其他資料</label>
            <input v-model="form.academic_other" id="academic_other" type="text" class="form-control w-full"
                   placeholder="請輸入其他資料">
            <div v-if="'academic_other' in errors" class="text-danger mt-2" v-html="errors.academic_other"></div>
          </div>
        </div>
      </div>

      <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
        <span class="font-medium">專業資格</span>
      </div>

      <div class="mt-3">
        <div class="flex">
          <div class="flex-1 mr-1">
            <label for="professional_qualifications" class="form-label">持有的專業資格</label>
            <input v-model="form.professional_qualifications" id="professional_qualifications" type="text"
                   class="form-control w-full" placeholder="請輸入持有的專業資格">
            <div v-if="'professional_qualifications' in errors" class="text-danger mt-2"
                 v-html="errors.professional_qualifications"></div>
          </div>

          <div class="flex-1 mr-1">
            <label for="qualification_authority" class="form-label">頒發機構全名</label>
            <input v-model="form.qualification_authority" id="qualification_authority" type="text"
                   class="form-control w-full" placeholder="請輸入頒發機構全名">
            <div v-if="'qualification_authority' in errors" class="text-danger mt-2"
                 v-html="errors.qualification_authority"></div>
          </div>

          <div class="flex-1">
            <label for="qualification_level" class="form-label">已達到的程度</label>
            <input v-model="form.qualification_level" id="qualification_level" type="text"
                   class="form-control w-full" placeholder="請輸入已達到的程度">
            <div v-if="'qualification_level' in errors" class="text-danger mt-2"
                 v-html="errors.qualification_level"></div>
          </div>
        </div>
      </div>

      <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
        <span class="font-medium">工作經驗</span>
      </div>

      <div class="mt-3">
        <div class="flex">
          <div class="flex-1 mr-1">
            <label for="past_company" class="form-label">公司名稱</label>
            <input v-model="form.past_company" id="past_company" type="text" class="form-control w-full"
                   placeholder="請輸入公司名稱">
            <div v-if="'past_company' in errors" class="text-danger mt-2" v-html="errors.past_company"></div>
          </div>

          <div class="flex-1 mr-1">
            <label for="past_position" class="form-label">職位</label>
            <input v-model="form.past_position" id="past_position" type="text" class="form-control w-full"
                   placeholder="請輸入職位">
            <div v-if="'past_position' in errors" class="text-danger mt-2" v-html="errors.past_position"></div>
          </div>

          <div class="flex-1">
            <label for="past_intro" class="form-label">描述</label>
            <input v-model="form.past_intro" id="past_intro" type="text" class="form-control w-full"
                   placeholder="請輸入描述">
            <div v-if="'past_intro' in errors" class="text-danger mt-2" v-html="errors.past_intro"></div>
          </div>
        </div>
      </div>

      <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
        <span class="font-medium">備註欄</span>
      </div>

      <div class="mt-3">
        <label for="remarks" class="form-label">補充資料及建議</label>
        <input v-model="form.remarks" id="remarks" type="text" class="form-control w-full" placeholder="請輸入補充資料及建議">
        <div v-if="'remarks' in errors" class="text-danger mt-2" v-html="errors.remarks"></div>
      </div>

      <div class="flex justify-between align-items-center mt-5">
        <div v-if="mode === 'update' && data">
          <button v-if="!loading && !finish && data.application_status === 'PENDING' && isApprover" type="button" class="btn btn-primary w-24 mr-1" @click="onOpenModal('approve')">批准</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING' && isApprover" type="button" class="btn btn-danger w-24 mr-1" @click="onOpenModal('reject')">拒絕</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING' && isApprover" type="button" class="btn btn-pending w-24 mr-1" @click="onOpenModal('request-modify')">要求修改</button>

          <button v-if="!loading && !finish && data.application_status === 'PENDING_BOSS' && isHighLevelApprover" type="button" class="btn btn-primary w-24 mr-1" @click="onOpenModal('high-level-approve')">批准</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING_BOSS' && isHighLevelApprover" type="button" class="btn btn-danger w-24 mr-1" @click="onOpenModal('high-level-reject')">拒絕</button>
          <button v-if="!loading && !finish && data.application_status === 'PENDING_BOSS' && isHighLevelApprover" type="button" class="btn btn-pending w-24 mr-1" @click="onOpenModal('high-level-request-modify')">要求修改</button>
        </div>

        <div>
          <router-link v-if="!loading && !finish" to="/workflows?type=account" type="button"
                       class="btn btn-outline-secondary w-24 mr-1">返回
          </router-link>
          <button v-if="!loading && !finish" type="button" class="btn btn-outline-pending w-24 mr-1"
                  @click="onSave(true)">儲存
          </button>
          <button v-if="!loading && !finish && !isApprover && !isHighLevelApprover" type="button" class="btn btn-primary w-24" @click="onSave(false)">遞交
          </button>
        </div>
        <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
      </div>
    </div>
    <!-- END: Form Layout -->

    <!-- BEGIN: Signature Modal -->
    <div id="signature-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ action != null ? (action.includes('high-level') ? '第三部份 -（由資訊科技填寫）' : '第二部份 - 直轄主管簽署（最低為組/服務單位主管/學校校長或副校長）') : '' }}
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body">
            <div>
              <label for="role-id" class="form-label">訊息</label>
              <textarea v-model="form.approval_message" ref="textarea" class="chat__box__input form-control dark:bg-darkmode-600 resize-none px-5 py-3 shadow-none focus:ring-0" rows="5" placeholder="請輸入訊息"></textarea>
            </div>
            <div class="mt-3" v-if="false">
              <label for="role-id" class="form-label">簽署</label>
              <div class="sign-area">
                <vue-signature ref="signature" :sigOption="option"></vue-signature>
              </div>
              <div class="flex mt-1">
                <button type="button" @click="clear" class="btn btn-outline-secondary flex-1 mr-1">清除</button>
                <button type="button" @click="undo" class="btn btn-outline-secondary flex-1">復原</button>
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer">
            <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
            <button v-if="!loading && !finish && action === 'request-modify'" type="button" class="btn btn-pending w-24" @click="onRequestModify">要求修改</button>
            <button v-if="!loading && !finish && action === 'reject'" type="button" class="btn btn-danger w-24" @click="onReject">拒絕</button>
            <button v-if="!loading && !finish && action === 'approve'" type="button" class="btn btn-primary w-24" @click="onApprove">批准</button>
            <button v-if="!loading && !finish && action === 'high-level-request-modify'" type="button" class="btn btn-pending w-24" @click="onHighLevelRequestModify">要求修改</button>
            <button v-if="!loading && !finish && action === 'high-level-reject'" type="button" class="btn btn-danger w-24" @click="onHighLevelReject">拒絕</button>
            <button v-if="!loading && !finish && action === 'high-level-approve'" type="button" class="btn btn-primary w-24" @click="onHighLevelApprove">批准</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Signature Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'data'],
  data() {
    return {
      mode: 'create',
      isCopy: false,
      mounted: false,
      staffNumber: null,
      form: {
        staff_number: null,
        application_type: '',

        avatar_base64: null,
        centre_id: '',
        position_id: '',
        is_high_level: false,
        is_register_social_worker: false,

        honorific_title: '',
        first_name_zh: null,
        last_name_zh: null,
        first_name_en: null,
        last_name_en: null,
        other_name_en: null,
        display_name: null,
        email: null,
        office_number: null,
        fax: null,
        contact_number: null,
        other_number: null,
        birthdate: null,
        special_skill: null,
        about_me: null,

        personal_website: null,
        blog_website: null,
        other_website: null,
        group: null,
        honors: null,
        interest: null,
        school: null,
        academic: null,
        academic_department: null,
        academic_other: null,
        professional_qualifications: null,
        qualification_authority: null,
        qualification_level: null,
        past_company: null,
        past_position: null,
        past_intro: null,
        remarks: null
      },

      isApprover: false,
      isHighLevelApprover: false,

      isExistUser: false,
      isDeletedUser: false,
      lastStaffNumber: null,
      isDraft: false,

      birthdate: null,

      errors: {},
      loading: false,
      finish: false,

      positions: [],
      centers: [],

      action: null,
      type: 'account'
    }
  },
  computed: {
    serverUrl() {
      return '' + process.env.VUE_APP_SERVER_URL
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    },
    async 'form.centre_id'(val) {
      this.setDisplayName()
    },
    async 'form.position_id'(val) {
      this.setDisplayName()
    },
    async 'form.first_name_en'() {
      this.$set(this.form, 'first_name_en', this.form.first_name_en ? this.form.first_name_en.toUpperCase() : '')
      this.setDisplayName()
    },
    async 'form.last_name_en'() {
      this.$set(this.form, 'last_name_en', this.form.last_name_en ? this.form.last_name_en.toUpperCase() : '')
      this.setDisplayName()
    },
    async 'form.other_name_en'() {
      this.$set(this.form, 'other_name_en', this.form.other_name_en ? this.form.other_name_en.toUpperCase() : '')
      this.setDisplayName()
    },
    data(val) {
      this.init()
    }
  },
  created() {
  },
  async mounted() {
    window.tailwind.svgLoader()
    await this.init()

    this.$nextTick(() => {
      this.dropZone()

      const self = this

      this.$dz.on('addedfile', function(file) {
        while (this.files.length > this.options.maxFiles) this.removeFile(this.files[0])

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function() {
          self.$set(self.form, 'avatar_base64', reader.result)
        }
      })
    })
  },
  methods: {
    async init() {
      if (this.currentSite) {
        this.mounted = true

        this.loadConfigs()
        await this.loadCenters()
        await this.loadPositions()

        if (this.data != null) {
          this.form = this.data
          this.staffNumber = this.form.staff_number
          this.birthdate = this.form.birthdate != null ? this.$moment(this.form.birthdate).toDate() : null
          this.form.centre_id = this.form.centre_id == null ? '' : this.form.centre_id
          this.form.position_id = this.form.position_id == null || this.form.position_id === 0 ? '' : this.form.position_id
          this.form.honorific_title = this.form.honorific_title == null || this.form.honorific_title === 0 ? '' : this.form.honorific_title
          this.mode = 'update'
          // await this.onCheckUserExist(false)

          if (this.$route.query.copy != null && this.$route.query.copy === 'true') {
            this.$emit('isCopy', true)
            this.mode = 'create'

            if (this.staffNumber != null && this.staffNumber !== '') {
              const response = await this.getUserStaffNumber(this.staffNumber)
              this.isExistUser = response.code === 0
            }
          }
        }
      }
    },
    async loadConfigs() {
      this.configs = await this.getSystemConfig('workflow')

      this.isApprover = parseInt(this.configs.approver_site_id) === parseInt(this.currentSite.site_id)
      this.isHighLevelApprover = parseInt(this.configs.high_level_approver_site_id) === parseInt(this.currentSite.site_id)
    },
    async onCheckUserExist(showAlert = true) {
      if (showAlert) {
        this.$set(this.form, 'application_type', '')
      }

      if (this.staffNumber != null && this.staffNumber !== '') {
        const response = await this.getUserStaffNumber(this.staffNumber)
        this.isExistUser = response.code === 0

        if (this.isExistUser) {
          this.isDeletedUser = response.data.is_deleted
          if (this.isDeletedUser) {
            this.$set(this.form, 'application_type', 'UPDATE')
          }

          this.form.staff_number = this.staffNumber
          this.form.centre_id = response.data.centre_id ?? ''
          this.form.position_id = response.data.position_id ?? ''
          this.form.honorific_title = response.data.honorific_title
          this.form.is_high_level = response.data.is_high_level
          this.form.is_register_social_worker = response.data.is_register_social_worker ?? false
          this.form.first_name_zh = response.data.first_name_zh
          this.form.last_name_zh = response.data.last_name_zh
          this.form.first_name_en = response.data.first_name
          this.form.last_name_en = response.data.last_name
          this.form.other_name_en = response.data.other_name_en
          this.birthdate = response.data.birthdate != null ? this.$moment(response.data.birthdate).toDate() : null
          this.form.email = response.data.email
          this.form.office_number = response.data.office_number
          this.form.fax = response.data.fax
          this.form.contact_number = response.data.contact_number
          this.form.other_number = response.data.other_number
          this.form.special_skill = response.data.special_skill
          this.form.about_me = response.data.about_me
          this.form.personal_website = response.data.personal_website
          this.form.blog_website = response.data.blog_website
          this.form.other_website = response.data.other_website
          this.form.group = response.data.group
          this.form.honors = response.data.honors
          this.form.interest = response.data.interest
          this.form.school = response.data.school
          this.form.academic = response.data.academic
          this.form.academic_department = response.data.academic_department
          this.form.academic_other = response.data.academic_other
          this.form.professional_qualifications = response.data.professional_qualifications
          this.form.qualification_authority = response.data.qualification_authority
          this.form.qualification_level = response.data.qualification_level
          this.form.past_company = response.data.past_company
          this.form.past_position = response.data.past_position
          this.form.past_intro = response.data.past_intro
          this.form.remarks = response.data.remarks
          this.onRemoveImage()
          this.setDisplayName()

          if (showAlert) {
            alert(this.isDeletedUser ? '此員工帳戶已被刪除，如要重新啟動可以進行更新' : '找到員工記錄，可更新或刪除')
          }
        } else {
          this.resetForm()
          if (showAlert) {
            alert('未有找到員工記錄，可新增')
          }
        }
      } else {
        this.isExistUser = false
        this.resetForm()
      }
    },
    async resetStaffNumber() {
      if (this.staffNumber != null && this.staffNumber !== '') {
        const response = await this.getUserStaffNumber(this.staffNumber)
        this.isExistUser = response.code === 0
      } else {
        this.isExistUser = false
      }
    },
    resetForm() {
      this.form = {
        staff_number: this.staffNumber,
        application_type: '',

        avatar_base64: null,
        centre_id: '',
        position_id: '',
        is_high_level: false,
        is_register_social_worker: false,

        honorific_title: '',
        first_name_zh: null,
        last_name_zh: null,
        first_name_en: '',
        last_name_en: '',
        other_name_en: '',
        display_name: null,
        email: null,
        office_number: null,
        fax: null,
        contact_number: null,
        other_number: null,
        birthdate: null,
        special_skill: null,
        about_me: null,

        personal_website: null,
        blog_website: null,
        other_website: null,
        group: null,
        honors: null,
        interest: null,
        school: null,
        academic: null,
        academic_department: null,
        academic_other: null,
        professional_qualifications: null,
        qualification_authority: null,
        qualification_level: null,
        past_company: null,
        past_position: null,
        past_intro: null,
        remarks: null
      }
      this.onRemoveImage()
    },
    onRemoveImage() {
      if (this.$dz) {
        this.$dz.removeAllFiles()
      }

      this.$set(this.form, 'avatar_base64', null)
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadPositions() {
      this.positions = await this.getPositions()
    },
    setDisplayName() {
      const firstName = this.form.first_name_en ?? ''
      const lastName = this.form.last_name_en ?? ''
      const otherName = this.form.other_name_en ?? ''
      let fullName = otherName == null || otherName === '' ? `${lastName} ${firstName}` : `${otherName} ${lastName}`
      fullName = fullName.toUpperCase()
      const centerAbbrev = this.getObjectValue(this.centers, 'centre_id', this.form.centre_id, 'abbrev')
      const positionName = this.getObjectValue(this.positions, 'position_id', this.form.position_id, 'name').toUpperCase()
      const displayName = `${fullName}[${centerAbbrev} - ${positionName}]`
      this.$set(this.form, 'display_name', displayName)
    },
    notAfterDate(date) {
      const now = new Date()
      return date > new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
    async onSave(isDraft) {
      this.loading = true
      this.errors = {}

      let response

      if (this.staffNumber != null && this.staffNumber !== '') {
        this.form.staff_number = this.staffNumber
      }

      if (this.birthdate != null) {
        this.form.birthdate = this.$moment(this.birthdate).format('YYYY-MM-DD')
      }

      if ('effective_date' in this.form) {
        delete this.form.effective_date
      }

      this.form.isDraft = isDraft

      if (this.mode === 'create') {
        response = await this.createWorkflow('account', this.isDraft, this.form)
      } else {
        response = await this.updateWorkflow('account', this.data.application_id, this.form)
      }

      if (response.body && 'errors' in response.body) {
        this.errors = response.body.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? (!isDraft ? '已成功遞交申請表' : '已成功儲存申請表') : '已成功更改申請表',
          message: '請稍後，將會轉至申請表頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          // if (!isDraft) {
          //   await this.confirmWorkflow('account', response.data.application_id)
          // }

          setTimeout(() => {
            this.$router.push('/workflows?type=account')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? (!isDraft ? '未能遞交申請表' : '未能儲存申請表') : '未能更改申請表',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    },
    formatPhone(field, event) {
      if (this.form[field] != null && this.form[field].length >= 4 && !this.form[field].includes(' ') && event.key !== 'Backspace') {
        const values = this.split(this.form[field], 4)
        this.$set(this.form, field, `${values[0]} ${values[1]}`)
      }
    },
    onOpenModal(action) {
      this.action = action
      const el = document.querySelector('#signature-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onRequestModify() {
      console.log(this.data.application_id)
      this.loading = true

      const response = await this.requestModifyWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已要求修改申請表',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能要求修改申請表',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onReject() {
      this.loading = true

      const response = await this.rejectWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已拒絕申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能拒絕申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onApprove() {
      this.loading = true

      const response = await this.approveWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已批準申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能批準申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onHighLevelRequestModify() {
      this.loading = true

      const response = await this.highLevelRequestModifyWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已要求修改申請表',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能要求修改申請表',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onHighLevelReject() {
      this.loading = true

      const response = await this.highLevelRejectWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已拒絕申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能拒絕申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    async onHighLevelApprove() {
      this.loading = true

      const response = await this.highLevelApproveWorkflow(this.type, this.data.application_id, this.form)

      let option = {
        title: '成功',
        message: '已批準申請',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true
        this.hideModal()

        setTimeout(() => {
          this.$router.push(`/workflows?type=${this.type}`)
        }, 1000)
      } else {
        option = {
          title: '未能批準申請',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
      this.loading = false
    },
    hideModal() {
      const el = document.querySelector('#signature-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()
    }
  }
}
</script>

<style scoped lang="scss">
#contact-form {
  .btn-remove {
    height: 38px;
  }
}
</style>
